import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPayout.module.css';

const IconPayout = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      viewBox="0 0 1024 1024"
      width="52"
      height="45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M810.666667 896H213.333333c-46.933333 0-85.333333-38.4-85.333333-85.333333V213.333333c0-46.933333 38.4-85.333333 85.333333-85.333333h597.333334c46.933333 0 85.333333 38.4 85.333333 85.333333v597.333334c0 46.933333-38.4 85.333333-85.333333 85.333333z" />
      <path d="M631.466667 597.333333c0 85.333333-81.066667 96-96 96v46.933334h-38.4v-46.933334c-12.8-2.133333-102.4-10.666667-102.4-113.066666h70.4c0 10.666667 0 59.733333 53.333333 59.733333 44.8 0 44.8-36.266667 44.8-40.533333 0-76.8-153.6-44.8-153.6-164.266667 0-72.533333 66.133333-96 96-96v-51.2h38.4v51.2c23.466667 2.133333 89.6 21.333333 89.6 110.933333h-70.4c0-19.2-4.266667-57.6-44.8-57.6-40.533333 0-40.533333 36.266667-40.533333 42.666667-2.133333 70.4 153.6 40.533333 153.6 162.133333z" fill="#FFFFFF" />
    </svg>
  );
};

const { string } = PropTypes;

IconPayout.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPayout.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPayout;
