import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconPayout, NamedLink } from '../../components';

import css from './ModalMissingInformation.module.css';

const PayoutReminder = props => {
  const { className, user } = props;

  const stripeLink = (
    <NamedLink name="StripePayoutPage" className={css.addPayoutLink}>
      <FormattedMessage id="ModalMissingInformation.addStripePayout" />
    </NamedLink>
  );
  const otherPayoutLink = (
    <NamedLink name="PayoutPage" className={css.addPayoutLink}>
      <FormattedMessage id="ModalMissingInformation.addOtherPayout" />
    </NamedLink>
  );
  return (
    <div className={className}>
      <IconPayout className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.missingPayoutDetails" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.addPayoutMethod" />
      </p>
      <p className={css.modalMessage}>
        <ul className={css.addPayoutLinks}>
          <li>{stripeLink}</li>
          <li>{otherPayoutLink}</li>
        </ul>
      </p>
    </div>
  );
};

export default PayoutReminder;
